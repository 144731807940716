/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import GetAllFlagData from './header/DropdownFlag/getAllFlagData'

const getLang = () => {
  const countryToLang = {
    BR: "pt-br",
    AR: "es-ar",
  }

  return countryToLang[process.env.GATSBY_COUNTRY] || "pt-br"
}

function SEO({ description, lang, meta, title, canonical, home, pathName, hrefLangLinks }) {
  const { site, schema } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        schema: wordpressGeneoneThemeSettings {
          facebook_domain_verification
        }
      }
    `
  )

  const dataSchema = {
    '@context': 'https://schema.org',
    '@type': 'DiagnosticLab',
    name: 'Dasa Genômica',
    url: 'https://www.dasagenomica.com/',
    logo: 'https://bkt-sa-east-1-cms-2-assets-prd.s3.sa-east-1.amazonaws.com/prd/sites/6/2022/08/17174502/AF_DASA_Logo_Genomica_FD_Branco_RGB.png',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '55 11 3003-7323',
        contactType: 'customer service',
        areaServed: 'BR',
        availableLanguage: 'Portuguese',
      },
      {
        '@type': 'ContactPoint',
        telephone: '11 99511-7323',
        contactType: 'customer service',
        areaServed: 'BR',
        availableLanguage: 'Portuguese',
      },
    ],
    sameAs: [
      'https://www.facebook.com/dasagenomica',
      'https://www.linkedin.com/showcase/dasagenomica/',
      'https://www.youtube.com/channel/UCJv4QaHLxJr5-3nN9ZysCrA/featured',
      'https://www.instagram.com/dasa.genomica/',
    ],
  }

  const metaDescription = description || site.siteMetadata.description
  const linkCanonical = canonicalUrl => {
    if (!canonicalUrl) {
      return {}
    }

    return {
      rel: 'canonical',
      key: canonicalUrl,
      href: canonicalUrl,
    }
  }

  const linkHrefLang = () => {
    const results = []

    if(hrefLangLinks) {
      if (hrefLangLinks.post_hreflang_br !== '') {
        results.push({
          rel: 'alternate',
          hrefLang: 'pt-br',
          href: hrefLangLinks.post_hreflang_br
        })
      }

      if (hrefLangLinks.post_hreflang_ar !== '') {
        results.push({
          rel: 'alternate',
          hrefLang: 'es-ar',
          href: hrefLangLinks.post_hreflang_ar
        })
      }
    } else if (pathName) {
        const flags = GetAllFlagData()
        const baseUrl = "https://www.dasagenomica.com";
        const fallbackUrl = `${baseUrl}${pathName}`;
  
        results.push(
          ...flags.map(flag => {
              const country = flag.sigla.toLowerCase()
              let href = ''

              switch (process.env.GATSBY_COUNTRY) {
                case "BR":
                  href = flag.hrefLang === 'pt-br'
                    ? fallbackUrl
                    : `${baseUrl}/${country}${pathName}`
                  break

                case "AR":
                  href = flag.hrefLang === 'pt-br'
                    ? fallbackUrl.replace("/ar", "")
                    : fallbackUrl
                  break

                default:
                  href = flag.hrefLang === 'pt-br'
                    ? fallbackUrl
                    : `${baseUrl}/${country}${pathName}`
                  break
              }

              return {
                rel: 'alternate',
                hrefLang: flag.hrefLang,
                href: href
              }
            }
          )
        )
    }
    
    return results
  }

  return (
    <Helmet
      link={[
        linkCanonical(canonical),
        ...linkHrefLang()
      ]}
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={!home ? `%s | ${site.siteMetadata.title}` : `${title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `og:locale`,
          content: lang,
        },
        {
          name: `og:url`,
          content: `https://www.dasagenomica.com/`,
        },
        {
          name: `og:site_name`,
          content: `Dasa Genômica`
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content: `index, follow`,
        },
        {
          name: `facebook-domain-verification`,
          content: schema.facebook_domain_verification,
        },
      ].concat(meta)}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(dataSchema),
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: getLang(),
  meta: [],
  description: ``,
  canonical: '',
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string,
}

export default SEO
